<template>
  <div class="flex flex-col">
    <div class="flex mb-2">
      <span class="text-promy-purple-400 w-full font-extrabold">
        Altimétrie :
      </span>
    </div>
    <div class="flex flex-col">
      <div class="flex gap-2 items-center">
        <button
          class="font-extrabold text-base flex justify-center items-center flex-grow py-1"
          :class="!is_corner_changed ? 'btn-green' : ' btn-white'"
          @click="addListenerClickOnCorner"
        >
          <template v-if="!is_corner_changed">
            <span>Changer les points </span>
          </template>

          <template v-else>Valider</template>
        </button>
        <button
          class="font-extrabold text-base flex justify-center items-center p-2 btn-green"
          v-tooltip.top="{
            content: `Recharger l'altimétrie`,
            classes:
              'z-50 bg-black text-white px-2 rounded mb-1 text-lg font-medium',
          }"
          @click="addCornersWithElevation"
        >
          <resetIcon />
        </button>
      </div>
      <div class="flex space-x-3 py-3 my-2 bg-promy-gray-50 rounded-lg">
        <Checkbox
          v-model="is_altimetrie_active"
          id="altimetrie-check"
          class="mb-5"
          :val="is_altimetrie_active"
        />
        <label
          for="altimetrie-check"
          class="cursor-pointer text-promy-gray-650 text-base pl-5"
          >Afficher l'altimétrie
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import parcelle from '@/mixins/cadastre/parcelle'
import batiment from '@/mixins/cadastre/batiment'
import informations_map from '@/mixins/dossierPromoteurs/informations/map'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      is_altimetrie_active: true,
    }
  },

  mixins: [
    helpers,
    calculeHelpers,
    Mapbox,
    parcelle,
    batiment,
    sourcesAndLayers,
    informations_map,
  ],
  props: {
    informations: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      elevations: 'parcelle/elevations',
    }),
  },
  watch: {
    is_altimetrie_active(is_active) {
      this.markers_altimetrie.forEach((marker) => {
        if (!marker.getPopup().isOpen() && is_active) {
          marker.togglePopup()
        } else if (marker.getPopup().isOpen() && !is_active) {
          marker.togglePopup()
        }
      })
    },
  },

  methods: {
    addListenerClickOnCorner() {
      if (this.is_parcelle_aggregate) {
        this.$toaster.warning('Veuillez valider vos choix de parcelles ')
      } else if (this.is_parcelle_changed) {
        this.$toaster.warning('Veuillez valider votre parcelle ')
      } else if (!this.is_corner_changed) {
        this.map.on('click', this.addCorner)
        this.$store.commit('parcelle/IS_CORNER_CHANGED', true)
      } else {
        this.map.off('click', this.addCorner)
        this.$store.commit('parcelle/IS_CORNER_CHANGED', false)
      }
    },
    addCorner(e) {
      let lon = e.lngLat.lng
      let lat = e.lngLat.lat
      let marker = this.addSingleCornerWithElevation([lon, lat])
      this.getElevation({ lon: lon, lat: lat }).then((elevation) => {
        marker.elevation = elevation.z
        this.addPopupWithElevation(marker)
      })
    },
    addSingleCornerWithElevation(corner) {
      let marker_el = this.addMarkerCircleToMap(corner)
      let marker = marker_el.marker
      marker_el.el.setAttribute(
        'coords',
        `${marker.getLngLat().lat},${marker.getLngLat().lng}`,
      )
      marker_el.el.addEventListener('click', this.removeCorner)
      this.$store.commit('parcelle/MARKERS_ALTIMETRIE', marker)
      return marker
    },
    addPopupWithElevation(marker) {
      let popup = new this.$mapboxgl.Popup({
        anchor: 'bottom',
        closeButton: false,
        closeOnClick: false,
      })
      popup.setHTML(
        ` <div class='flex flex-col text-promy-purple-400 pt-1 pl-1 pr-1'>  <span class='font-bold'> Altimétrie </span>  <span> ${marker.elevation} M </span> </div> `,
      )
      marker.setPopup(popup)
    },
    addCornerCrossMarkersAltimetrie() {
      this.removeMarkers(this.markers_altimetrie)
      this.$store.commit('parcelle/MARKERS_ALTIMETRIE', [])

      this.informations.altimetries.forEach((altimetrie) => {
        let temp_marker = this.addSingleCornerWithElevation(
          altimetrie.coordinates,
        )
        temp_marker.elevation = altimetrie.elevation
        this.addPopupWithElevation(temp_marker)
        temp_marker.togglePopup()
      })
    },
    addCornersWithElevation() {
      this.informations.altimetries = []
      this.removeMarkers(this.markers_altimetrie)
      this.$store.commit('parcelle/MARKERS_ALTIMETRIE', [])
      let corners = this.getCornersGeojson(this.getUnionParcelles())

      corners.forEach((corner) => {
        this.addSingleCornerWithElevation(corner)
      })
      let lngs = this.markers_altimetrie
        .map((marker) => marker.getLngLat().lng)
        .join('|')
      let lats = this.markers_altimetrie
        .map((marker) => marker.getLngLat().lat)
        .join('|')
      this.getElevation({ lon: lngs, lat: lats }).then((elevations) => {
        this.markers_altimetrie.forEach((marker, index) => {
          marker.elevation = elevations[index].z
          this.addPopupWithElevation(marker)
          marker.togglePopup()
        })
      })
    },

    removeCorner(e) {
      if (this.is_corner_changed) {
        let coords = e.target.parentNode.getAttribute('coords')
        var indexOfMarker = this.markers_altimetrie.findIndex(
          (marker) =>
            `${marker.getLngLat().lat},${marker.getLngLat().lng}` === coords,
        )
        if (this.isExist(indexOfMarker)) {
          this.markers_altimetrie[indexOfMarker].remove()
          this.markers_altimetrie.splice(indexOfMarker, 1)
        }
        e.stopPropagation()
      }
    },
    getElevation(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get('address', {
            params: {
              lon: params.lon,
              lat: params.lat,
              type: 'elevation',
            },
          })
          .then((response) => {
            let temp_elevations = response.data.elevation.elevations
            let elevation =
              temp_elevations.length > 1 ? temp_elevations : temp_elevations[0]
            resolve(elevation)
          })
          .catch(() => {
            reject('altimetrie non disponible')
          })
      })
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.off('click', this.addCorner)
    }
  },
}
</script>
